<div class="page-container" [ngClass]="{ expanded: isExpanded }">
    <div class="page-content map-page">
        <!-- Title Bar -->
        <div class="title-bar" [ngClass]="{ 'p-0 border-0': !showTopBar }">
            <div class="title" *ngIf="showTopBar">
                <h1 class="ellipsis" title="{{ mapDefinition?.name }}">
                    <button zmid="back" type="button" class="btn btn-round me-1 back-button" (click)="back()" title="{{ 'BACK' | translate }}">
                        <fa-icon icon="chevron-left" size="md"></fa-icon></button
                    >{{ mapDefinition?.name }}
                </h1>
            </div>
            <div class="actions" *ngIf="showTopBar">
                <div class="form-inline justify-content-end">
                    <!-- Edit/Clone -->
                    <div class="btn-group me-2" role="group">
                        <button type="button" class="btn btn-outline-primary" (click)="gotoEditMapForm()" title="{{ 'EDIT' | translate }}">
                            <fa-icon icon="pencil" size="sm"></fa-icon>
                            <span class="d-none d-md-inline-block ms-1">{{ "EDIT" | translate }}</span>
                        </button>
                        <button type="button" class="btn btn-outline-primary" (click)="gotoCloneMapForm()" title="{{ 'CLONE' | translate }}">
                            <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                            <span class="d-none d-md-inline-block ms-1">{{ "CLONE" | translate }}</span>
                        </button>
                    </div>
                    <!-- Report -->
                    <button type="button" class="btn btn-outline-secondary me-2" title="{{ 'DOWNLOAD_EXCEL_FILE' | translate }}" (click)="exportToExcel()">
                        <fa-icon icon="file-excel" size="md"></fa-icon>
                    </button>
                    <!-- Legend -->
                    <div ngbDropdown display="dynamic" [placement]="['bottom-right', 'bottom-left']">
                        <button class="btn btn-outline-secondary me-2" id="sortByDropdown" ngbDropdownToggle title="{{ 'LEGEND' | translate }}">
                            <fa-icon icon="map-signs"></fa-icon>
                            <span class="sr-only">{{ "LEGEND" | translate }}</span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="sortByDropdown" class="shadow-sm no-top-border mt-1">
                            <app-map-legend></app-map-legend>
                        </div>
                    </div>
                    <!-- Refresh -->
                    <button
                        [disabled]="editMap || loadingMap"
                        type="button"
                        class="btn btn-outline-secondary me-2"
                        (click)="refresh()"
                        title="{{ 'REFRESH' | translate }}"
                    >
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshingMap"></fa-icon>
                        <span class="sr-only">{{ "REFRESH" | translate }}</span>
                    </button>
                    <!-- Expand -->
                    <button type="button" class="btn btn-outline-secondary" (click)="toggleExpand()" title="{{ 'EXPAND' | translate }}" *ngIf="!isExpanded">
                        <fa-icon icon="expand" size="sm"></fa-icon>
                        <span class="sr-only">{{ "EXPAND" | translate }}</span>
                    </button>
                    <!-- Contract -->
                    <button type="button" class="btn btn-outline-secondary" (click)="toggleExpand()" title="{{ 'CONTRACT' | translate }}" *ngIf="isExpanded">
                        <fa-icon icon="compress" size="sm"></fa-icon>
                        <span class="sr-only">{{ "CONTRACT" | translate }}</span>
                    </button>
                </div>
            </div>
            <div class="toggle-topbar-btn" *ngIf="isExpanded" (click)="toggleTopBar()">
                <fa-icon icon="angle-double-up" size="xs" *ngIf="showTopBar"></fa-icon>
                <fa-icon icon="angle-double-down" size="xs" *ngIf="!showTopBar"></fa-icon>
            </div>
        </div>
        <div class="content-area p-0">
            <div class="map-container" [ngClass]="{ hasCluster: hasCluster }">
                <spinner-animation *ngIf="loadingMap && count === 0"></spinner-animation>
                <div leaflet id="map" [leafletOptions]="options" [leafletLayersControl]="layersControl" (leafletMapReady)="onMapReady($event)">
                    <div class="leaflet-center" *ngIf="savingMap">
                        {{ "SAVING" | translate }}
                        <div class="spinner-border" role="status" [ngStyle]="{ 'width.rem': 1, 'height.rem': 1 }"></div>
                    </div>
                    <div class="leaflet-top leaflet-left" [ngClass]="{ showingDetails: showDetails }">
                        <!-- Details -->
                        <div class="object-details" *ngIf="showDetails">
                            <h5 class="sr-only">{{ "DETAILS" | translate }}</h5>
                            <button aria-label="Close" class="close" type="button" (click)="closeDetails(); $event.stopPropagation()">
                                <span aria-hidden="true">×</span>
                            </button>
                            <div class="text-center" *ngIf="loadingDetails">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">{{ "LOADING" | translate }}...</span>
                                </div>
                            </div>
                            <!--feeder-->
                            <app-feeder-content
                                *ngIf="currentFeeder"
                                [(feeder)]="currentFeeder"
                                [canEdit]="canEdit(currentFeeder)"
                                [dataMarker]="selectedMapObject"
                            ></app-feeder-content>
                            <!---receiver-->
                            <app-receiver-content
                                *ngIf="currentReceiver"
                                [(receiver)]="currentReceiver"
                                [canEdit]="canEdit(currentReceiver)"
                                [dataMarker]="selectedMapObject"
                            ></app-receiver-content>
                            <!---zec-->
                            <app-zec-content
                                *ngIf="currentZec"
                                [(zec)]="currentZec"
                                [canEdit]="canEdit(currentZec)"
                                [dataMarker]="selectedMapObject"
                            ></app-zec-content>
                            <!--broadcaster-->
                            <app-broadcaster-content
                                *ngIf="currentBroadcaster"
                                [(broadcaster)]="currentBroadcaster"
                                [canEdit]="canEdit(currentBroadcaster)"
                                [dataMarker]="selectedMapObject"
                            ></app-broadcaster-content>
                            <!--source-->
                            <app-source-content
                                *ngIf="currentSource"
                                [(source)]="currentSource"
                                [canEdit]="canEdit(currentSource)"
                                [dataMarker]="selectedMapObject"
                            ></app-source-content>
                            <!-- mediaconnect source -->
                            <app-mc-source-content
                                *ngIf="currentMediaConnectSource"
                                [(source)]="currentMediaConnectSource"
                                [canEdit]="canEdit(currentMediaConnectSource)"
                                [dataMarker]="selectedMapObject"
                            ></app-mc-source-content>
                            <!--target-->
                            <app-target-content *ngIf="currentTarget" [(target)]="currentTarget" [dataMarker]="selectedMapObject"></app-target-content>
                            <!-- -->
                            <!-- Move Marker -->
                            <div class="move-marker" *ngIf="!loadingDetails">
                                <hr />
                                <button *ngIf="!movingMarkerByAddress" type="button" class="btn btn-sm btn-primary" (click)="moveMarkerClick()">
                                    <fa-icon icon="search" size="sm" class="me-1"></fa-icon>{{ "MOVE_MARKER" | translate }}
                                </button>
                                <div *ngIf="movingMarkerByAddress">
                                    <div class="input-group mb-0">
                                        <button
                                            class="btn btn-sm btn-primary"
                                            type="button"
                                            id="search-button"
                                            [disabled]="!searchAddressVal || searchAddressVal.length <= 3"
                                            (click)="addressLookup(searchAddressVal); $event.stopPropagation()"
                                        >
                                            <fa-icon icon="search" size="sm"></fa-icon>
                                        </button>
                                        <input
                                            type="text"
                                            class="form-control form-control-sm form-control-xs"
                                            placeholder="{{ 'SEARCH_ADDRESS' | translate }}..."
                                            [(ngModel)]="searchAddressVal"
                                            (keyup)="$event.keyCode === 13 || addressLookup(searchAddressVal)"
                                            (click)="$event.stopPropagation()"
                                        />
                                        <button
                                            class="btn btn-sm btn-outline-light"
                                            type="button"
                                            (click)="searchAddressVal = null; searchAddressResults = null"
                                            *ngIf="searchAddressVal && searchAddressVal.length > 0"
                                        >
                                            <fa-icon icon="times" size="md"></fa-icon>
                                        </button>
                                    </div>
                                    <!-- Results -->
                                    <div class="map-results-list" *ngIf="searchAddressResults && searchAddressVal && searchAddressVal.length > 3">
                                        <h5 class="sr-only">{{ "RESULTS" | translate }}</h5>
                                        <ul *ngIf="searchAddressResults.length > 0 && !searchingAddress">
                                            <li
                                                class="ellipsis"
                                                *ngFor="let result of searchAddressResults"
                                                (click)="moveMarkerByAddress(result); $event.stopPropagation()"
                                            >
                                                <a href="javascript:void(0)" title="{{ result.displayName }}">{{ result.displayName }}</a>
                                            </li>
                                        </ul>
                                        <p class="mb-0" *ngIf="searchAddressResults.length === 0 && !searchingAddress">{{ "NO_SEARCH_RESULTS" | translate }}</p>
                                        <p class="mb-0" *ngIf="searchingAddress">{{ "SEARCHING" | translate }}...</p>
                                    </div>
                                    <!-- Cancel -->
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary mt-2"
                                        (click)="movingMarkerByAddress = !movingMarkerByAddress"
                                    >
                                        <fa-icon icon="times" size="sm" class="me-1"></fa-icon>{{ "CANCEL" | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- Search Markers -->
                        <div
                            class="marker-lookup"
                            *ngIf="!showDetails && !showNewObjects"
                            [ngClass]="{
                                hasResults: (markerResults || tagResults) && !showDetails && !showNewObjects && searchMarkerVal && searchMarkerVal.length > 0
                            }"
                        >
                            <div class="input-group shadow-custom">
                                <input
                                    type="text"
                                    class="form-control form-control-sm form-control-xs"
                                    placeholder="{{ 'SEARCH_MARKERS_BY_NAME_AND_TAGS' | translate }}..."
                                    [(ngModel)]="searchMarkerVal"
                                    (keyup)="$event.keyCode === 13 || markerLookup(searchMarkerVal) || tagLookup(searchMarkerVal)"
                                    (click)="$event.stopPropagation()"
                                />
                                <button
                                    class="btn btn-sm btn-outline-light"
                                    type="button"
                                    (click)="searchMarkerVal = null; markerResults = null; tagResults = null; $event.stopPropagation()"
                                    *ngIf="searchMarkerVal && searchMarkerVal.length > 0"
                                >
                                    <fa-icon icon="times" size="md"></fa-icon>
                                </button>
                            </div>
                            <div
                                class="marker-results-list list-shadow"
                                *ngIf="(markerResults || tagResults) && !showDetails && searchMarkerVal && searchMarkerVal.length > 0"
                            >
                                <p *ngIf="markerResults.length === 0 && tagResults.length === 0" class="mb-0 p-2">{{ "NO_RESULTS" | translate }}</p>
                                <!-- Marker Results -->
                                <div *ngIf="markerResults.length > 0">
                                    <h6>{{ "MARKERS" | translate }}</h6>
                                    <ul *ngIf="markerResults.length > 0">
                                        <li
                                            class="ellipsis"
                                            *ngFor="let result of markerResults"
                                            (click)="selectMarkerResult(result); $event.stopPropagation()"
                                        >
                                            <zx-feeder
                                                *ngIf="result.type === 'feeder'"
                                                [model]="result.obj"
                                                [showStatusIcon]="true"
                                                [showStatusText]="false"
                                                [showLink]="false"
                                                [showTag]="true"
                                                [searchTerm]="searchMarkerVal"
                                            ></zx-feeder>
                                            <zx-receiver
                                                *ngIf="result.type === 'receiver'"
                                                [model]="result.obj"
                                                [showStatusIcon]="true"
                                                [showStatusText]="false"
                                                [showLink]="false"
                                                [showTag]="true"
                                                [searchTerm]="searchMarkerVal"
                                            ></zx-receiver>
                                            <zx-zec
                                                *ngIf="result.type === 'zec'"
                                                [model]="result.obj"
                                                [showStatusIcon]="true"
                                                [showStatusText]="false"
                                                [showLink]="false"
                                                [showTag]="true"
                                                [searchTerm]="searchMarkerVal"
                                            ></zx-zec>
                                            <zx-broadcaster
                                                *ngIf="result.type === 'broadcaster'"
                                                [model]="result.obj"
                                                [showCluster]="true"
                                                [showStatusIcon]="true"
                                                [showStatusText]="false"
                                                [showLink]="false"
                                                [showTag]="true"
                                                [searchTerm]="searchMarkerVal"
                                            ></zx-broadcaster>
                                            <zx-source
                                                *ngIf="result.type === 'source' && !result.subtype"
                                                [model]="result.obj"
                                                [showStatusIcon]="true"
                                                [showStatusText]="false"
                                                [showLink]="false"
                                                [showTag]="true"
                                                [searchTerm]="searchMarkerVal"
                                            ></zx-source>
                                            <zx-mediaconnect-source
                                                *ngIf="result.type === 'source' && result.subtype === 'mediaconnect'"
                                                [model]="result.obj"
                                                [showStatusIcon]="true"
                                                [showStatusText]="false"
                                                [showLink]="false"
                                                [showTag]="true"
                                                [searchTerm]="searchMarkerVal"
                                            ></zx-mediaconnect-source>
                                            <zx-target
                                                [model]="result.obj"
                                                [showStatusIcon]="true"
                                                [showStatusText]="false"
                                                [showLink]="false"
                                                [showTag]="true"
                                                [searchTerm]="searchMarkerVal"
                                                *ngIf="result.type === 'target'"
                                            ></zx-target>
                                        </li>
                                    </ul>
                                    <p class="m-0 p-2" *ngIf="markerResults.length === 0">{{ "NO_MARKERS" | translate }}</p>
                                </div>
                                <!-- Tag Results -->
                                <div *ngIf="tagResults.length > 0">
                                    <h6>{{ "TAGS" | translate }}</h6>
                                    <ul *ngIf="tagResults.length > 0">
                                        <li class="ellipsis" *ngFor="let result of tagResults" (click)="selectTagResult(result); $event.stopPropagation()">
                                            <ngb-highlight [result]="result" [term]="searchMarkerVal"></ngb-highlight>
                                        </li>
                                    </ul>
                                    <p class="m-0 p-2" *ngIf="tagResults.length === 0">{{ "NO_TAGS" | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="leaflet-top leaflet-right">
                        <div class="extra-map-controls">
                            <!-- Cluster -->
                            <button
                                *ngIf="!clustered"
                                type="button"
                                class="btn btn-outline-secondary me-3"
                                title="{{ 'CLUSTER' | translate }}"
                                (click)="clusterMarkers(); $event.stopPropagation()"
                            >
                                <fa-icon icon="object-group" size="md"></fa-icon>
                                <span class="sr-only">{{ "CLUSTER" | translate }}</span>
                            </button>
                            <!-- Uncluster -->
                            <button
                                *ngIf="clustered"
                                type="button"
                                class="btn btn-outline-secondary me-3"
                                title="{{ 'UNCLUSTER' | translate }}"
                                (click)="unclusterMarkers(); $event.stopPropagation()"
                            >
                                <fa-icon icon="object-ungroup" size="md"></fa-icon>
                                <span class="sr-only">{{ "UNCLUSTER" | translate }}</span>
                            </button>
                            <!-- Recenter -->
                            <button
                                type="button"
                                class="btn btn-outline-secondary"
                                title="{{ 'RECENTER' | translate }}"
                                (click)="recenter(); $event.stopPropagation()"
                            >
                                <fa-icon icon="compress-arrows-alt" size="sm"></fa-icon>
                                <span class="sr-only">{{ "RECENTER" | translate }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="leaflet-bottom leaflet-left" [ngClass]="{ showNewObjects: showNewObjects }">
                        <!-- Show New Objects -->
                        <div
                            class="show-new-objects"
                            *ngIf="
                                unplacedObjects &&
                                unplacedObjects.length > 0 &&
                                !showDetails &&
                                !showNewObjects &&
                                currentVisibleLayers.length > 0 &&
                                getVisibleLayersWithUnplacedObjects().length > 0 &&
                                count > 0
                            "
                        >
                            <button class="btn btn-sm btn-outline-secondary" type="button" (click)="showNewObjects = true; $event.stopPropagation()">
                                <fa-icon icon="stream" size="sm" class="me-1"></fa-icon>{{ "SHOW_UNSAVED_MARKERS" | translate }}
                            </button>
                        </div>
                        <!-- New Objects -->
                        <div
                            class="new-objects"
                            *ngIf="
                                unplacedObjects &&
                                unplacedObjects.length > 0 &&
                                !showDetails &&
                                showNewObjects &&
                                currentVisibleLayers.length > 0 &&
                                getVisibleLayersWithUnplacedObjects().length > 0 &&
                                count > 0
                            "
                        >
                            <h4>{{ "MARKERS_WITH_NO_SAVED_LOCATION" | translate }}</h4>
                            <button aria-label="Close" class="close" type="button" (click)="closeNewObjects(); $event.stopPropagation()">
                                <span aria-hidden="true">×</span>
                            </button>
                            <div *ngFor="let group of unplacedObjects">
                                <div
                                    *ngIf="
                                        ((group.feeders && group.feeders.length > 0) ||
                                            (group.receivers && group.receivers.length > 0) ||
                                            (group.zecs && group.zecs.length > 0) ||
                                            (group.broadcasters && group.broadcasters.length > 0) ||
                                            (group.sources && group.sources.length > 0) ||
                                            (group.mediaconnect_sources && group.mediaconnect_sources.length > 0) ||
                                            (group.targets && group.targets.length > 0)) &&
                                        currentVisibleLayers.indexOf(group.name) > -1
                                    "
                                >
                                    <h5>{{ group.name }}</h5>
                                    <ul>
                                        <li *ngFor="let feeder of group.feeders">
                                            <button
                                                class="btn btn-sm btn-outline-secondary"
                                                type="button"
                                                (click)="centerOnObject('feeder', feeder, group, null)"
                                            >
                                                <zx-feeder
                                                    [model]="feeder"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="false"
                                                    [showTag]="true"
                                                ></zx-feeder>
                                            </button>
                                        </li>
                                        <li *ngFor="let receiver of group.receivers">
                                            <button
                                                class="btn btn-sm btn-outline-secondary"
                                                type="button"
                                                (click)="centerOnObject('receiver', receiver, group, null)"
                                            >
                                                <zx-receiver
                                                    [model]="receiver"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="false"
                                                    [showTag]="true"
                                                ></zx-receiver>
                                            </button>
                                        </li>
                                        <li *ngFor="let zec of group.zecs">
                                            <button class="btn btn-sm btn-outline-secondary" type="button" (click)="centerOnObject('zec', zec, group, null)">
                                                <zx-zec
                                                    [model]="zec"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="false"
                                                    [showTag]="true"
                                                ></zx-zec>
                                            </button>
                                        </li>
                                        <li *ngFor="let broadcaster of group.broadcasters">
                                            <button
                                                class="btn btn-sm btn-outline-secondary"
                                                type="button"
                                                (click)="centerOnObject('broadcaster', broadcaster, group, null)"
                                            >
                                                <zx-broadcaster
                                                    [model]="broadcaster"
                                                    [showCluster]="true"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="false"
                                                    [showTag]="true"
                                                ></zx-broadcaster>
                                            </button>
                                        </li>
                                        <li *ngFor="let source of group.sources">
                                            <button
                                                class="btn btn-sm btn-outline-secondary"
                                                type="button"
                                                (click)="centerOnObject('source', source, group, null)"
                                            >
                                                <zx-source
                                                    [model]="source"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="false"
                                                    [showTag]="true"
                                                ></zx-source>
                                            </button>
                                        </li>
                                        <li *ngFor="let source of group.mediaconnect_sources">
                                            <button
                                                class="btn btn-sm btn-outline-secondary"
                                                type="button"
                                                (click)="centerOnObject('source', source, group, 'mediaconnect')"
                                            >
                                                <zx-mediaconnect-source
                                                    [model]="source"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="false"
                                                    [showTag]="true"
                                                ></zx-mediaconnect-source>
                                            </button>
                                        </li>
                                        <li *ngFor="let t of group.targets">
                                            <button
                                                class="btn btn-sm btn-outline-secondary"
                                                type="button"
                                                (click)="centerOnObject('target', t.target, group, t.type)"
                                            >
                                                <zx-target
                                                    [model]="t"
                                                    [showStatusIcon]="true"
                                                    [showStatusText]="false"
                                                    [showLink]="false"
                                                    [showTag]="true"
                                                ></zx-target>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <small>*&nbsp;{{ "MARKERS_ARE_REMOVED_FROM_THIS_LIST_ONCE_THEY_ARE_MOVED_ON_MAP" | translate }}</small>
                        </div>
                    </div>
                    <div class="leaflet-bottom leaflet-right">
                        <div class="move-marker-buttons">
                            <!-- Move Markers -->
                            <button
                                *ngIf="!editMap"
                                [disabled]="loadingMap"
                                type="button"
                                class="btn btn-sm btn-outline-secondary"
                                title="{{ 'MOVE_MARKERS' | translate }}"
                                (click)="enableEdit(); $event.stopPropagation()"
                            >
                                <fa-icon icon="map-marker-edit"></fa-icon>
                                <span class="d-none d-sm-inline-block ms-1">{{ "MOVE_MARKERS" | translate }}</span>
                            </button>
                            <form id="form" #form="ngForm" (ngSubmit)="form.valid && saveMap()">
                                <button
                                    *ngIf="editMap"
                                    type="button"
                                    class="btn btn-sm btn-outline-secondary me-2"
                                    title="{{ 'CANCEL' | translate }}"
                                    (click)="cancelEdit(); $event.stopPropagation()"
                                >
                                    <fa-icon icon="times"></fa-icon>
                                    <span class="d-none d-sm-inline-block ms-1">{{ "CANCEL" | translate }}</span>
                                </button>
                                <button *ngIf="editMap" zmid="form-submit" type="submit" class="btn btn-sm btn-primary" title="{{ 'SAVE' | translate }}">
                                    <fa-icon icon="map-marker-check"></fa-icon>
                                    <span class="d-none d-sm-inline-block ms-1">{{ "SAVE" | translate }}</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
